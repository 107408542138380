
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'semantic-ui-css/semantic.min.css'

import Home from "./Pages/Home";

function App() {
  return (
    <Router>
      <div>
        <Switch>

          <Route >
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
