import { Component } from "react";
import { GridColumn, Segment, Grid, GridRow, Image, Input, Icon, Button, Card } from "semantic-ui-react";
import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


//const servidor = "http://plugpikback.plugprint.com.br:3345";
const servidor = "http://plugpikb.plugprint.com.br:3345";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      senha: "",
      cota:"10",
      arquivos: [],
      acesso: "none",
      usuario: "",
      carregandoDownload: false,
      carregandoRefresh: false
    }
  }

  componentDidMount() {
    if (cookies.get('senha') != undefined) {
      this.setState({ senha: cookies.get('senha') });

    }

    //alert(cookies.get('senha'));
  }

  listaArquivos = () => {
    //alert("Listando");
    if (this.state.senha != "") {
      //alert("list")
      axios.get(servidor + "/listar?senha=" + this.state.senha)
        .then(res => {
          const dados = res.data;
          //alert(dados.arquivos);
          console.log(res.data);
          this.setState({ arquivos: dados.arquivos });
          this.setState({ carregandoRefresh: false });
        })
    }
  }
  baixarArquivo = async () => {
    //alert(this.state.senha);
    this.setState({ carregandoDownload: true });
    await axios.get(servidor + "/baixar?senha=" + this.state.senha + "&link=" + this.state.link)
      .then(res => {
        const dados = res.data;
        console.log(res.data);
        this.listaArquivos();
        this.setState({ carregandoDownload: false });
        this.setState({ link: "" });
        //this.setState({ arquivos: dados });
      })

  }

  acessaConta = () => {
    if (this.state.senha == "") {
      alert("Digite sua senha de acesso!");
    } else {
      axios.get(servidor + "/logar?senha=" + this.state.senha)
        .then(res => {
          const dados = res.data;
          console.log(res.data);
          if (dados.Logado === true) {
            this.setState({ usuario: dados.Usuario });
            this.setState({ cota: dados.Cota });
            this.setState({ acesso: "block" });
            cookies.set('senha', this.state.senha, { path: '/' });
            this.listaArquivos();
          } else {
            alert("Senha Incorreta!");
            this.setState({ senha: "" });
          }

        })
    }
    //alert("ooooo");
  }

  render() {
    var login = (<Input action={{
      color: 'blue',
      labelPosition: 'right',
      icon: 'key',
      content: 'Acessar',
      onClick: () => { this.acessaConta() }
    }} color="success" placeholder='Senha de acesso' onChange={(event) => {
      this.setState({ senha: event.target.value });
    }} value={this.state.senha} />);

    if (this.state.usuario !== "") {
      login = (<div>
        <Button
          color='red'
          content='Sair'
          icon='sign-out'
          labelPosition="left"
          label={{ basic: true, color: 'red', pointing: 'right', content: this.state.usuario }}
          onClick={() => {
            this.setState({ usuario: "" });
            this.setState({ acesso: "none" });

          }} />
      </div>);
    } else { }
    return (
      <div><br></br>
        <Grid>
          <GridRow>
            <GridColumn width="2"></GridColumn>
            <GridColumn width="12">
              <Segment>
                <Grid>
                  <GridRow>
                    <GridColumn width="8">  <Image src="imagens/logoplugpik.png" width="300" />
                    </GridColumn>
                    <GridColumn width="8" verticalAlign='middle' textAlign="right">
                      {login}
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Segment>
              <div style={{ display: this.state.acesso }}>
                <Segment color="blue" style={{ display: (this.state.arquivos.length >= this.state.cota ? "none" : "block") }}>
                  <Input fluid action={{
                    loading: this.state.carregandoDownload,
                    color: 'green',
                    labelPosition: 'left',
                    icon: 'cloud download',
                    content: 'Baixar',
                    onClick: () => { this.baixarArquivo() }
                  }} color="success" placeholder='Link Freepik' onChange={(event) => {
                    this.setState({ link: event.target.value });
                  }} value={this.state.link} />
                </Segment>
                <div align="right" style={{ justifyContent: "space-between", flex: 1 }}><p textAlign="right" style={{ color: "red", fontWeight: "bold" }}>{this.state.cota - this.state.arquivos.length + " Downloads disponíveis para hoje. "}<Button loading={this.state.carregandoRefresh} icon="refresh" color='twitter' onClick={() => {
                  this.listaArquivos();
                  this.setState({ carregandoRefresh: true });
                }} /></p></div>
                <Segment>
                  <Card.Group itemsPerRow={5}>
                    {this.state.arquivos.map((arquivo) => {
                      return (
                        <Card>
                          <Image title={arquivo.nome} src={"/imagens/" + arquivo.extensao + ".jpg"} wrapped ui={false} />
                          <Card.Content>
                            <Card.Description textAlign="center">
                              {arquivo.nome.substring(0, 20) + "..."}
                            </Card.Description>
                          </Card.Content>
                          <Card.Content extra>
                            <div className='ui two buttons'>
                              <Button as="a" href={servidor+"/download?arquivo=" + arquivo.arquivo} target="_blank" Icon="download" basic color='green'>
                                Download
                              </Button>

                            </div>
                          </Card.Content>
                        </Card>


                      )
                    })}
                  </Card.Group>
                </Segment></div>
            </GridColumn>
            <GridColumn width="2"></GridColumn>
          </GridRow>
        </Grid><br></br>
        <div style={{ color: "grey" }} align="center"><p>contato@plugprint.com.br </p><Button icon="whatsapp" color="green" as="a" href="http://wa.me/5535999703731" target="_blank" /></div>
      </div>
    )
  }
}